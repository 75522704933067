<template>
	<div class="order">
		<div class="menu">
			<el-collapse v-model="activeNames">
				<el-collapse-item title="卡项" name="1">
					<div class="my-menu-item" :class="{cur:defaultActive=='5-0'}" @click="getItemList('5-0')">划卡</div>
					<!-- <div class="my-menu-item" :class="{cur:defaultActive=='5-1'}" @click="getItemList('5-1')">办卡</div> -->
					<div class="my-menu-item" :class="{cur:defaultActive=='5-2'}" @click="getItemList('5-2')">充值</div>
                    <div class="my-menu-item" :class="{cur:defaultActive=='5-3'}" @click="getItemList('5-3')">会员卡</div>
				</el-collapse-item>
				<el-collapse-item title="品牌套餐" name="6">
					<div class="my-menu-item" :class="{cur:defaultActive=='6-'+index}" @click="getItemList('6-'+index)"
						v-for="(item,index) in brandGroupClassify" :key="index">{{item.name}}</div>
				</el-collapse-item>
				<el-collapse-item title="品牌项目" name="2">
					<div class="my-menu-item" :class="{cur:defaultActive=='1-'+index}" @click="getItemList('1-'+index)"
						v-for="(item,index) in brandClassify" :key="index">{{item.name}}</div>
				</el-collapse-item>
				<el-collapse-item title="品牌商品" name="4">
					<div class="my-menu-item" :class="{cur:defaultActive=='3-'+index}" @click="getItemList('3-'+index)"
						v-for="(item,index) in brandGoodsClassify" :key="index">{{item.name}}</div>
				</el-collapse-item>
				<el-collapse-item title="自营套餐" name="7">
					<div class="my-menu-item" :class="{cur:defaultActive=='7-'+index}" @click="getItemList('7-'+index)"
						v-for="(item,index) in selfGroupClassify" :key="index">{{item.name}}</div>
				</el-collapse-item>
				<el-collapse-item title="自营项目" name="3">
					<div class="my-menu-item" :class="{cur:defaultActive=='2-'+index}" @click="getItemList('2-'+index)"
						v-for="(item,index) in selfClassify" :key="index">{{item.name}}</div>
				</el-collapse-item>
				<el-collapse-item title="自营商品" name="5">
					<div class="my-menu-item" :class="{cur:defaultActive=='4-'+index}" @click="getItemList('4-'+index)"
						v-for="(item,index) in selfGoodsClassify" :key="index">{{item.name}}</div>
				</el-collapse-item>
			</el-collapse>
			<!-- <el-menu :default-active="defaultActive" @select="getItemList">
				<template>
					<el-menu-item-group>
						<span slot="title">卡项</span>
						<el-menu-item index="5-0">划卡</el-menu-item>
						<el-menu-item index="5-1">办卡</el-menu-item>
						<el-menu-item index="5-2">充值</el-menu-item>
					</el-menu-item-group>
					<el-menu-item-group>
						<span slot="title">品牌项目</span>
						<el-menu-item :index="'1-'+index" v-for="(item,index) in brandClassify" :key="index">{{item.name}}</el-menu-item>
					</el-menu-item-group>
					<el-menu-item-group>
						<span slot="title">自营项目</span>
						<el-menu-item :index="'2-'+index" v-for="(item,index) in selfClassify" :key="index">{{item.name}}</el-menu-item>
					</el-menu-item-group>
					<el-menu-item-group>
						<span slot="title">品牌商品</span>
						<el-menu-item :index="'3-'+index" v-for="(item,index) in brandGoodsClassify" :key="index">{{item.name}}</el-menu-item>
					</el-menu-item-group>
					<el-menu-item-group>
						<span slot="title">自营商品</span>
						<el-menu-item :index="'4-'+index" v-for="(item,index) in selfGoodsClassify" :key="index">{{item.name}}</el-menu-item>
					</el-menu-item-group>
				</template>
			</el-menu> -->
		</div>
		<div class="content">
			<div class="wrap">
				<!-- 展示划卡 -->
				<template v-if="pageType == 'member'">
					<el-card v-if="member">
						<div class="grid col-2">
							<div class="flex align-center">
								<div class="text-grey margin-right">会员姓名</div>
								<div class="cont">{{member.real_name}}</div>
							</div>
							<div class="flex align-center">
								<div class="text-grey margin-right">会员余额</div>
								<div class="cont">{{member.balance}}</div>
							</div>
							<!-- <div class="flex align-center">
								<div class="text-grey margin-right">会员卡</div>
								<div class="cont">{{memberCardCount}}张</div>
							</div> -->
						</div>
					</el-card>
					<el-card v-else>
						<div class="grid col-1">
							<div class="flex align-center">
								请选择会员
							</div>
						</div>
					</el-card>
					<!-- <div class="text-lg margin-top" v-if="member">会员卡权益</div>
					<div class="card" v-if="member">
						<div class="card-box" v-for="(card,index) in memberCards" :key="card.id">
							<div class="card-name">{{index+1}}. {{card.card_name}}</div>
							<div class="flex padding">
								<div class="items flex-sub">
									<div class="item not-select" :class="{cur:item==curItem}" v-for="item in card.items"
										@click="addCardServe(item)" :key="item.id">
										<el-image :src="item.cover" fit="cover">
											<div slot="error" class="el-image__error">{{vuex_project}}</div>
										</el-image>
										<div class="cont">
											<div class="tit line-2">{{item.name}}</div>
											<div class="price-box">
												<div class="price" v-if="item.price">￥{{item.price}}</div>
												<div>{{item.rest}}/{{item.count}}</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="card-box text-center text-grey padding" v-if="!memberCards.length">
							无会员卡
						</div>
					</div> -->
					<div class="text-lg margin-top" v-if="member">套餐权益</div>
					<div class="card" v-if="member">
						<div class="card-box" v-for="(group,index) in memberGroups" :key="group.id">
							<div class="card-name">{{index+1}}. {{group.set_meal_name}}</div>
							<div class="flex padding">
								<div class="items flex-sub">
									<div class="item not-select" :class="{cur:item==curItem}"
										v-for="item in group.items" @click="addGroupItem(item)" :key="item.id">
										<el-image :src="item.cover" fit="cover">
											<div slot="error" class="el-image__error">{{vuex_project}}</div>
										</el-image>
										<div class="cont">
											<div class="tit line-2">{{item.name}}</div>
											<div class="price-box">
												<div class="price" v-if="item.price">￥{{item.price}}</div>
												<div>{{item.rest}}/{{item.count}}</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="card-box text-center text-grey padding" v-if="!memberGroups.length">
							无套餐权益
						</div>
					</div>
                    <template v-if="memberVipCards.length && member">
                        <div class="text-lg margin-top">超级会员权益</div>
                        <div class="card">
                            <div class="card-box" v-for="(card,index) in memberVipCards" :key="card.id">
                                <div class="card-name">
                                    {{index+1}}. {{card.name}}
                                    <span class="margin-left-lg">价格：{{card.price}}</span>
                                </div>
                                
                                <div class="flex padding">
                                    <div class="items flex-sub">
                                        <div class="item not-select" :class="{cur:item==curItem}" v-for="item in card.ticket"
                                            @click="addCardVip(item)" :key="item.id">
                                            <el-image :src="item.actitem.images[0]" fit="cover">
                                                <div slot="error" class="el-image__error">{{vuex_project}}</div>
                                            </el-image>
                                            <div class="cont">
                                                <div class="tit line-2">{{item.name}}</div>
                                                <div class="price-box">
                                                    <div class="price" v-if="item.price">￥{{item.price}}</div>
                                                    <div>{{item.left_num}}/{{item.total_num}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
				</template>
				<!-- 展示服务卡 -->
				<template v-if="pageType == 'cardServe'">
					<div class="flex">
						<div class="items flex-sub">
							<div class="item not-select" :class="{cur:card==curServeCard}" v-for="card in serveCards"
								@click="serveCardClick(card)" :key="card.id">
								<el-image :src="card.cover" fit="cover">
									<div slot="error" class="el-image__error">{{vuex_project}}</div>
								</el-image>
								<div class="cont">
									<div class="tit line-2">{{card.name}}</div>
									<div class="price-box">
										<div class="price" v-if="card.price">￥{{card.price}}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</template>
				<!-- 展示充值卡 -->
				<template v-if="pageType == 'cardRecharge'">
					<div class="flex">
						<div class="items flex-sub">
							<div class="item not-select" :class="{cur:card==curItem}" v-for="card in rechargeCards"
								@click="rechargeCardClick(card)"  :key="card.id">
								<!-- <el-image :src="card.cover" fit="cover">
									<div slot="error" class="el-image__error">{{vuex_project}}</div>
								</el-image> -->
								<div class="cont">
									<div class="tit line-2">{{card.card_name}}</div>
									<div class="price-box">
										<div class="price">￥{{card.recharge_money}}</div>
										<div class="price" style="font-size: 14px;" v-if="Number(card.give)">
											赠￥{{card.give}}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</template>
                <!-- 展示会员卡 -->
                <template v-if="pageType == 'cardVip'">
                	<div class="flex">
                		<div class="items flex-sub">
                			<div class="item not-select" :class="{cur:card==curItem}" v-for="card in vipCards"
                				@click="vipCardClick(card)"  :key="card.id">
                				<!-- <el-image :src="card.cover" fit="cover">
                					<div slot="error" class="el-image__error">{{vuex_project}}</div>
                				</el-image> -->
                				<div class="cont">
                					<div class="tit line-2">
                                        <span class="text-green" v-if="card.is_platform">[品牌]</span>
                                        <span class="text-blue" v-else>[自营]</span>
                                        {{card.name}}
                                    </div>
                					<div class="price-box">
                						<div class="price">￥{{card.price}}</div>
                						<!-- <div class="price" style="font-size: 14px;" v-if="Number(card.give)">
                							赠￥{{card.give}}</div> -->
                					</div>
                				</div>
                			</div>
                		</div>
                	</div>
                </template>
				<!-- 展示项目或商品 -->
				<template v-if="pageType == 'project'">
					<!-- <div class="flex">
						<div class="flex-sub">
							<el-tabs v-model="activeName" type="card" @tab-click="tabMenuClick">
								<el-tab-pane label="全部" name="all"></el-tab-pane>
								<el-tab-pane :label="item.name" :name="item.id.toString()"
									v-for="(item,index) in tabMenu" :key="index"></el-tab-pane>
							</el-tabs>
						</div>
					</div> -->
					<div class="flex align-center justify-start" style="margin-bottom: 20px">
						<el-input v-model="name" placeholder="请输入商品名"></el-input>
						<el-button type="primary" @click="getItemList(number)">查询</el-button>
					</div>
					<div class="flex">
						<div class="items flex-sub">
							<div class="item not-select" :class="{cur:item==curItem}" v-for="item in items"
								@click="setSpecs(item)" :key="item.id">
								<el-image :src="item.cover" fit="cover">
									<div slot="error" class="el-image__error">{{vuex_project}}</div>
								</el-image>
								<div class="cont">
									<div class="tit line-2">{{item.name}}</div>
									<div class="price-box">
										<div class="price" v-if="item.price">￥{{item.price}}</div>
										<!-- <div class="count" :class="{cur:item.count}">
									<template v-if="item.count">
										<i class="el-icon-remove" @click.stop="countChange(-1,item)"></i>
										<el-input v-model.number="item.count" size="mini" @click.native.stop="inputClick()"></el-input>
									</template>
									<i class="el-icon-circle-plus" @click.stop="countChange(1,item)"></i>
								</div> -->
									</div>
								</div>
							</div>
						</div>
					</div>
				</template>
			</div>
		</div>
		<div class="width-300 border-left">
			<div class="text-center padding border-bottom system-status">
				<el-button :type="scanStatus" round v-if="scanStatus=='warning'" @click="clearScanGun()">输入中，按回车确认，点击清除
				</el-button>
				<el-button :type="scanStatus" round v-if="scanStatus=='success'">系统状态：正常</el-button>
				<el-button :type="scanStatus" round v-if="scanStatus=='danger'">系统状态：失去焦点</el-button>
                <div class="input-text">{{scanGun.cont}}</div>
			</div>
			<div class="specs" v-loading="specLoading">
				<div class="padding-sm goods_name" v-if="curItem">
					<div>{{curItem.name}}</div>
					<div>请选择规格：</div>
				</div>
				<div class="spec flex justify-between align-center" v-for="item in specs" :key="item.id">
					<!-- 如果是套餐 6- 品牌 或 7- 自营 要显示明细  -->
					<el-popover v-if="item.myId&&(item.myId.startsWith('6-')||item.myId.startsWith('7-'))" placement="left"
						trigger="hover">
						<el-table :data="item.detail">
							<el-table-column property="type" label="类型">
								<template slot-scope="scope">
									<el-tag :type="scope.row.type == '商品' ? 'danger':'success'">{{scope.row.type}}</el-tag>
								</template>
							</el-table-column>
							<el-table-column property="fullname" width="200" label="名称"></el-table-column>
							<el-table-column property="number" label="数量"></el-table-column>
							<el-table-column property="price" label="单价"></el-table-column>
						</el-table>
						<!-- <el-button size="small" type="warning" slot="reference" @mouseenter="loadSpecItems(item)">{{item.name}}</el-button> -->
						<el-tag style="cursor: default;" type="warning" slot="reference"
							@mouseenter="loadSpecItems(item)">{{item.name}}</el-tag>
					</el-popover>
					<el-tag type="warning" v-else>{{item.name}}</el-tag>
					<div class="count" :class="{cur:item.count}">
						<!-- <template v-if="item.count">
							<i class="el-icon-remove" @click.stop="countChange(-1,item)"></i>
							<el-input v-model.number="item.count" size="mini" @keydown.native.stop="inputClick"></el-input>
						</template> -->
						<div class="text-red margin-right-sm">￥{{item.price}}</div>
						<el-image class="plus" :src="require('../../assets/icon-plus.png')" @click.stop="addItem(item)"
							fit="cover"></el-image>
						<!-- <i class="el-icon-circle-plus" @click.stop="addItem(item)"></i> -->
					</div>

				</div>
			</div>
		</div>
		<div class="right">
			<div class="member-box">
				<div class="title">会员信息</div>
				<el-autocomplete class="member-input" popper-class="my-autocomplete" v-model="memberName"
					:fetch-suggestions="queryMember" placeholder="输入会员姓名/手机尾号快速搜索" @select="memberSelect"
					@keydown.native.stop="">
					<template slot-scope="{ item }">
						<div class="name">{{ item.real_name }}</div>
						<span class="phone">{{ item.phone }}</span>
					</template>
				</el-autocomplete>
				<div class="member" v-if="member">
					<el-image v-if="member.avatar" :src="member.avatar" fit="cover">
						<div slot="error" class="el-image__error">{{vuex_project}}</div>
					</el-image>
					<el-avatar v-else icon="el-icon-user-solid" shape="square"></el-avatar>
					<div class="user-info">
						<div class="name">{{member.real_name}}</div>
						<div class="balance">账户:￥{{member.balance}}<span v-if="Number(member.parent_balance) > 0">/总账户:￥{{member.parent_balance}}</span></div>
					</div>
					<i class="el-icon-circle-close" @click="clearMember"></i>
				</div>
			</div>
			<div class="order-detail-box">
				<div class="title">消费明细</div>
				<!-- <div class="order-detail-tit">
					<div class="name">名称</div>
					<div class="price">单价</div>
					<div class="count">数量</div>
					<div class="amount">金额</div>
				</div> -->
				<div class="order-detail-scroll">
					<div class="box padding-bottom-sm">
						<div class="order-detail2" v-for="(item) in detailList" :key="item.addTime">
							<div class="info">
								<div class="margin-bottom-sm">{{item.name}}</div>
								<div class="flex justify-between margin-bottom-xs">
									<div class="spec">规格：{{item.specName||'无'}}</div>
									<div class="total-price">￥{{item.totalPrice|toFixed2}}</div>
								</div>
								<div class="flex justify-between align-center">
									<div class="price">单价：￥{{item.price}}</div>
									<div class="flex align-center">
										<div>数量：</div>
										<div style="width: 108px;">
											<!-- <el-input-number v-model="changeItem.item.number" :disabled="changeItem.item.disabledNumber" :step="1" :min="1"
											:max="99999" @change="changeItemNumber" @keydown.native.stop="changeItemNuberKeyDown"></el-input-number> -->
                                            <!-- 划会员权益 -->
											<el-input-number v-if="item.type == 'usevipcard'" v-model="item.number" @change="changeItemNumber"
												style="width: 100%;" :min="0" :max="item.rest" size="mini"></el-input-number>
                                            <!-- 购买会员卡 -->
                                            <el-input-number v-else-if="item.type == 'vipcard'" v-model="item.number" @change="changeItemNumber"
                                                	style="width: 100%;" :min="0" :max="1" size="mini"></el-input-number>
                                            <!-- 其他 -->
                                            <el-input-number v-else v-model="item.number" @change="changeItemNumber"
                                                	style="width: 100%;" :min="0" :max="999" size="mini"></el-input-number>
										</div>
									</div>
								</div>
							</div>
							<div class="staff flex justify-between align-center" v-if="item.isService">
								<div>选择服务人员：</div>
								<div>
									<el-select v-model="item.technician" placeholder="请选择" size="mini"
										style="width:108px" clearable @change="technicianChange(item)">
										<el-option :label="item.nickname" :value="item.id" v-for="item in technicians"
											:key="item.id"></el-option>
									</el-select>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="salemain-box flex justify-between align-center">
				<div>销售人员：</div>
				<div>
					<el-select v-model="form.sale" placeholder="无" size="mini" style="width:108px" clearable
						@change="salemenChange">
						<el-option :label="item.nickname" :value="item.id" v-for="item in salemens" :key="item.id">
						</el-option>
					</el-select>
				</div>
				<!-- <div class="title">销售人员</div>
				<el-radio-group class="staffs" v-model="form.sale" @change="salemenChange">
					<div class="staff" v-for="(item,index) in salemens">
						<el-radio :label='item.id'>{{item.nickname}}</el-radio>
					</div>
				</el-radio-group> -->
			</div>
			<div class="staff-box border-top">
				<!-- <div class="title">收款方式</div> -->
				<el-radio-group class="payments" v-model="form.pay_method">
					<el-radio :label='2' class="payment alipay" :class="{cur:form.pay_method==2}">
						<el-image :src="require('../../assets/alipay.png')" style="width: 20px; height: 20px;">
						</el-image>
						<span class="text-blue">支付宝</span>
					</el-radio>
					<el-radio :label='1' class="payment wxpay" :class="{cur:form.pay_method==1}">
						<el-image :src="require('../../assets/wxpay.png')" style="width: 20px; height: 20px;">
						</el-image>
						<span class="text-green">微信</span>
					</el-radio>
					<el-radio :label='4' class="payment cash" :class="{cur:form.pay_method==4}">
						<el-image :src="require('../../assets/cash.png')" style="width: 20px; height: 20px;"></el-image>
						<span class="text-orange">现金</span>
					</el-radio>
					<el-radio :label='3' class="payment balancepay" :class="{cur:form.pay_method==3}">
						<el-image :src="require('../../assets/balancepay.png')" style="width: 20px; height: 20px;">
						</el-image>
						<span class="text-orange">余额</span>
					</el-radio>
				</el-radio-group>
			</div>
			<div class="settlement-box">
				<div class="total-amount">
					<div>总额：<span>￥{{form.actual_fee|toFixed2}}</span></div>
					<div class="discount">已优惠：<span>￥{{discount.discount|toFixed2}}</span></div>
				</div>
				<div>
					<el-button type="success" @click="discount.dialogVisible = true">优惠</el-button>
					<el-button type="primary" @click="submit" @keydown.native.enter.prevent="myvoid()">收款</el-button>
				</div>
			</div>
		</div>
		<el-dialog title="修改消费明细" :visible.sync="changeItem.dialogVisible" width="500px" :append-to-body="true"
			:close-on-click-modal="false">
			<div>
				<el-form ref="form" :model="changeItem.item" :rules="changeItem.rules" label-width="80px">
					<el-form-item label="名称" prop="name">
						{{changeItem.item.name}}
					</el-form-item>
					<el-form-item label="数量" prop="number">
						<el-input-number v-model="changeItem.item.number" :disabled="changeItem.item.disabledNumber"
							:step="1" :min="1" :max="99999" @change="changeItemNumber"
							@keydown.native.stop="changeItemNuberKeyDown"></el-input-number>
					</el-form-item>
					<el-form-item label="单价" prop="price">
						{{changeItem.item.price|toFixed2}}
					</el-form-item>
					<el-form-item label="金额" prop="amount">
						{{changeItem.item.totalPrice|toFixed2}}
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="danger" @click="changeItemDel()">删除</el-button>
				<el-button type="primary" @click="changeItem.dialogVisible = false">确定</el-button>
			</span>
		</el-dialog>
		<el-dialog title="优惠" :visible.sync="discount.dialogVisible" width="500px" :append-to-body="true"
			:close-on-click-modal="false">
			<div>
				<el-form ref="form" label-width="80px">
					<el-form-item label="总额" prop="number">
						{{totalFee}}
					</el-form-item>
					<el-form-item label="优惠金额" prop="number">
						<el-input-number v-model="discount.discount" :step="1" :min="0" :max="99999"
							@change="sumActualFee()" @keydown.native.stop="discountChange"></el-input-number>
					</el-form-item>
					<el-form-item label="实收金额" prop="number">
						<el-input-number v-model="form.actual_fee" :step="1" :min="0" :max="99999"
							@change="changeActual()" @keydown.native.stop="discountChange"></el-input-number>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="discount.dialogVisible = false">确定</el-button>
			</span>
		</el-dialog>
		<!-- 扫码 -->
		<el-dialog title="请扫描顾客的支付码" :visible.sync="scan.dialogVisible" width="500px" :append-to-body="true"
			:close-on-click-modal="false">
			<div class="text-center">
				请扫描顾客的
				<div class="text-green text-lg" v-if="form.pay_method == 1">微信付款码</div>
				<div class="text-blue text-lg" v-if="form.pay_method == 2">支付宝付款码</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="scan.dialogVisible = false" id="cancel">取消</el-button>
			</span>
		</el-dialog>
		<!-- 补款弹框 -->
		<el-dialog title="请扫描顾客的支付码" :visible.sync="rechargeForm.dialogVisible" width="500px" :append-to-body="true"
			:close-on-click-modal="false">
			<div class="text-center">
				请扫描顾客的
				<div class="text-green text-lg" v-if="rechargeForm.pay_method == 1">微信付款码</div>
				<div class="text-blue text-lg" v-if="rechargeForm.pay_method == 2">支付宝付款码</div>
				<div class="text-orange text-lg" v-if="rechargeForm.pay_method == 4">请收取顾客现金</div>
			</div>
			<div class="text-center padding text-orange">补款金额：{{rechargeForm.actual_fee}}</div>
			<div class="recharge-pay-method">
				<el-radio-group class="payments" v-model="rechargeForm.pay_method">
					<el-radio :label='2' class="payment alipay" :class="{cur:rechargeForm.pay_method==2}">
						<el-image :src="require('../../assets/alipay.png')" style="width: 20px; height: 20px;">
						</el-image>
						<span class="text-blue">支付宝</span>
					</el-radio>
					<el-radio :label='1' class="payment wxpay" :class="{cur:rechargeForm.pay_method==1}">
						<el-image :src="require('../../assets/wxpay.png')" style="width: 20px; height: 20px;">
						</el-image>
						<span class="text-green">微信</span>
					</el-radio>
					<el-radio :label='4' class="payment cash" :class="{cur:rechargeForm.pay_method==4}">
						<el-image :src="require('../../assets/cash.png')" style="width: 20px; height: 20px;"></el-image>
						<span class="text-orange">现金</span>
					</el-radio>
				</el-radio-group>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button ref="cancelBtn" @click="rechargeForm.dialogVisible = false" @keydown.native.enter.prevent="myvoid()">取消</el-button>
				<el-button @click="rechargeSubmit()" v-if="rechargeForm.pay_method == 4">已收取现金</el-button>
			</span>
		</el-dialog>
        <el-dialog title="支付中" :visible.sync="waitUserPass.dialogVisible" width="500px" :append-to-body="true"
			:close-on-click-modal="false" :show-close="false">
			<div class="flex justify-center flex-direction align-center">
                <div style="width: 100px;height:100px;" v-loading="true"></div>
				<div>等待支付...</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="waitComplete()">完成</el-button>
			</span>
        </el-dialog>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				name:'',
				number:'',
                // 等待顾客输入密码（付款超过1000元）
                waitUserPass:{
                    dialogVisible: false,
                    orderId: '',
                    sit: null, // 循环查询接口状态
                },
				// 规格详情加载
				specLoading: false,
				// 规格详情
				specItems: [{
					date: '2016-05-02',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄'
				}, {
					date: '2016-05-04',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄'
				}, {
					date: '2016-05-01',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄'
				}, {
					date: '2016-05-03',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄'
				}],
				activeNames: ['1','2','3','4','5','6','7'],
				// 扫码枪相关功能
				scanGun: {
					focus: true,
					cont: ''
				},
				scan: {
					dialogVisible: false
				},
				// 左侧分类选中状态
				defaultActive: '1-0',
				// 二级分类
				tabMenu: [],
				// 二级分类选中状态
				activeName: 'all',
				staff2: '',
				memberName: '',
				member: undefined,
				// 会员划卡的会员卡
				memberCards: [],
				memberCardCount: 0,
                // 会员的已购买超级会员卡
                memberVipCards: [],
				// 会员的已购买套餐
				memberGroups: [],
				memberGroupCount: 0,
				changeItem: {
					dialogVisible: false,
					item: {}
				},
				// 优惠
				discount: {
					dialogVisible: false,
					discount: 0
				},
				// 项目、商品列表
				curItem: null,
				// 当前选中的充值卡
				curRechargeCard: null,
				curRechargeCardForForm: null, // 用于消费明细
               
				// 当前选中的服务卡
				curServeCard: null,
				items: [],
				// 规格选项
				specs: [],
				members: [
					// {
					// value: "会员",
					// phone: "15500000001",
					// balance: "5880.00"
					// }
				],
                // 会员卡
                vipCards: [],
				// 充值卡
				rechargeCards: [],
				// 服务卡
				serveCards: [],
				// 品牌项目分类
				brandClassify: [],
				// 自营项目分类
				selfClassify: [],
				// 品牌套餐分类
				brandGroupClassify: [],
				// 自营套餐分类
				selfGroupClassify: [],
				// 品牌商品分类
				brandGoodsClassify: [],
				// 自营商品分类
				selfGoodsClassify: [],
				// 店员列表（销售人员）
				salemens: [{
					nickname: '无',
					id: ''
				}],
				// 技师
				technicians: [],
				// 充值提交的表单数据
				rechargeForm: {
					dialogVisible: false,
					actual_fee: 0, // 实际支付金额
					member_id: '', // 会员id
					auth_code: '', // 支付码
					pay_method: 1, // 支付类型  1 微信  2 支付宝  3 会员余额（充值不能使用余额） 4 现金
					memberRecharge: {
						payment_amount: 0, //充值金额
						money: 0, // 赠送金额
					}
				},
				// 提交的表单
				form: {
					auth_code: '',
					brandProduct: [], // {id,specs_id,number}
					selfProduct: [], // {id,number}
					brandServe: [], // {id,technician,number}
					selfServe: [], // {id,technician,number}
					brandSetMeal: [], // {id,number} 品牌套餐
					selfSetMeal: [], // {id,number} 自营套餐
                    memberSetMeal: [], // {id: 38,number:1,technician:6} 如果是商品，则不需要传入technician
					member_id: '', // 会员id
					sale: '', // 销售人员
					actual_fee: 0, // 实付金额
					discount_amount: 0, // 优惠金额
					rechargeCard: '', // 充值卡id
					serveCard: [], // 办卡，服务卡
                    vipCard: [], // 超级会员卡
					memberServeCard: [], // 划卡
                    memberVipCard:[], // 划会员卡
					pay_method: 1 ,// 支付类型  1 微信  2 支付宝  3 会员余额 4 现金
					number:1
				}
			};
		},
		computed: {
			// 扫码枪状态（是否可用）
			scanStatus() {
				if (this.scanGun.cont == '' && this.scanGun.focus) {
					// 正常
					return 'success'
				} else if (this.scanGun.cont) {
					// 输入中
					return 'warning'
				}
				// 不可用
				return 'danger'
			},
			// 消费明细列表
			detailList() {
				let list = [
					...this.form.serveCard,
                    ...this.form.vipCard,
					...this.form.memberServeCard,
					...this.form.brandProduct,
					...this.form.selfProduct,
					...this.form.brandServe,
					...this.form.selfServe,
					...this.form.brandSetMeal,
					...this.form.selfSetMeal,
					...this.form.memberSetMeal,
                    ...this.form.memberVipCard
				];
				// 如果有充值卡，则把充值卡也放入列表中
				if (this.curRechargeCardForForm) {
					list.push(this.curRechargeCardForForm)
				}
                
                
				list.sort((a, b) => a.addTime - b.addTime)
				return list;
			},
			// 应付金额
			totalFee() {
				let total = 0;
				this.detailList.forEach(item => {
					total += Number(item.totalPrice);
				});
				return total.toFixed(2)
			},
			// 页面类型，控制展示
			pageType() {
				let da = this.defaultActive.split('-')
				if (da[0] == 1 || da[0] == 2 || da[0] == 3 || da[0] == 4 || da[0] == 6 || da[0] == 7) {
					return 'project'
				}
				if (this.defaultActive == '5-0') {
					return 'member'
				}
				if (this.defaultActive == '5-1') {
					return 'cardServe'
				}
				if (this.defaultActive == '5-2') {
					return 'cardRecharge'
				}
                if (this.defaultActive == '5-3') {
                	return 'cardVip'
                }
				return '';
			}
		},
		mounted() {
			// 初始化扫码枪事件
			this.initScanGun()

			this.$api.classify.getAllClassify().then(res => {
				// 品牌商品分类
				res.data.brandProductClassify.unshift({
					id: undefined,
					name: '全部'
				})
				this.brandGoodsClassify = res.data.brandProductClassify
				// 品牌服务分类
				res.data.brandServeClassify.unshift({
					id: undefined,
					name: '全部'
				})
				this.brandClassify = res.data.brandServeClassify
				// 品牌套餐、自营套餐分类
				this.brandGroupClassify = this.brandClassify
				// this.selfGroupClassify = this.brandClassify
				this.selfGroupClassify.unshift({
					id: undefined,
					name: '全部'
				})
				// 自营商品分类
				res.data.selfProductClassify.unshift({
					id: undefined,
					name: '全部'
				})
				this.selfGoodsClassify = res.data.selfProductClassify
				// 自营服务分类
				res.data.selfServeClassify.unshift({
					id: undefined,
					name: '全部'
				})
				this.selfClassify = res.data.selfServeClassify
			})
			// 根据当前分类，获取对应的服务/商品
			this.getItemList('1-0')
			// 获取所有店员(销售人员即所有用户)
			this.$api.user.getUsers({
				status: 1, // 查询启用的用户
				page: 1,
				page_size: 999
			}).then(res => {
				this.salemens.push(...res.data.data);
			})
			// 获取所有技师
			this.$api.staff.getStaffs({
				page: 1,
				page_size: 999
			}).then(res => {
				this.technicians = res.data.data;
			})
			this.initPageData();
		},
		destroyed() {
			// 清除扫码枪事件
			this.destroyScanGun()
		},
		watch: {
			member() {
				if (this.member) {
					this.form.member_id = this.member.id
				} else {
					this.form.member_id = ''
				}
			}
		},
		filters: {
			amount(item) {
				return Number(item.price) * item.count
			},
			toFixed2(val) {
				// debugger
				return Number(val).toFixed(2)
			}
		},
		methods: {
            myvoid(){
                // console.log('enter myvoid')
                return false;
            },
            // 等待顾客
            waitUser(callback){
                this.waitUserPass.dialogVisible = true;
                if(this.waitUserPass.orderId){
                    this.waitUserPass.sit = setInterval(()=>{
                        this.$api.order.getOrderByIdOrCode({id: this.waitUserPass.orderId}).then(res=>{
                            if(res.data.order_status == 5){
                                clearInterval(this.waitUserPass.sit)
                                this.waitUserPass.dialogVisible = false;
                                callback && callback();
                                this.$message.success('收款成功！')
                            }else if(res.data.order_status == -1){
                                clearInterval(this.waitUserPass.sit)
                                this.waitUserPass.dialogVisible = false;
                                this.$alert('收款失败！',{ type: 'warning'});
                            }
                        })
                    }, 2000)
                }
            },
            waitComplete(){
                clearInterval(this.waitUserPass.sit)
                this.waitUserPass.orderId = '';
                this.waitUserPass.dialogVisible = false;
            },
			// 加载套餐包含哪些项目
			loadSpecItems(item) {
				console.log(item)
			},
			// 选择技师
			technicianChange(ser) {
				// 找到这个技师，查看该技师是否可以做该服务
				let tec = this.technicians.find(item => {
					return item.id == ser.technician
				})
				if (tec) {
					if (ser.type == 'brand' && !~Object.keys(tec.serve.brand).indexOf('' + ser.id)) {
						// 品牌服务中没找到该服务，提示
						this.$message.error('该技师不可服务此项目，如需修改，请至技师管理中服务配置勾选“可服务”')
					} else if (ser.type == 'self' && !~Object.keys(tec.serve.self).indexOf('' + ser.id)) {
						// 自营服务中没找到该项目，提示
						this.$message.error('该技师不可服务此项目，如需修改，请至技师管理中服务配置勾选“可服务”')
					}
				}
			},
			initPageData() {
				// 获取所有会员
				this.$api.member.getMembers({
					page: 1,
					page_size: 999
				}).then(res => {
					this.members = res.data.data;
				})
			},
			// 补款
			rechargeSubmit() {
				this.rechargeForm.dialogVisible = false;
				this.rechargeForm.member_id = this.form.member_id;
				this.rechargeForm.memberRecharge.payment_amount = this.rechargeForm.actual_fee; // 充值金额
				const loading = this.$loading()
				return this.$api.order.create(this.rechargeForm).then(res => {
					loading.close()
					if (res.code == 200) {
						this.$message({
							type: 'success',
							message: '操作成功!'
						});
                        // 等待付款完成
                        this.waitUserPass.orderId = res.data.order_id;
                        this.waitUser(()=>{
                            this.initPageData()
                            // 设置当前会员的余额
                            this.member.balance = Number(this.member.balance) + Number(this.rechargeForm.actual_fee)
                            this.member.balance = this.member.balance.toFixed(2)
                        })
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				});
			},
			// 点击选择服务卡
			serveCardClick(card) {
				this.curServeCard = card;
				// 添加到消费明细-服务卡
				this.form.serveCard.push({
					type: 'servecard',
					id: card.id,
					name: card.name,
					number: 1,
					price: card.price,
					totalPrice: card.price,
					addTime: new Date().getTime()
				})
				this.sumActualFee()
			},
            // 将会员卡添加到明细中
            addCardVip(card){
                console.log(card)
                // 查找是否已经包含此卡
                if(this.form.memberVipCard.find(item=>item.act_item_id == card.act_item_id)){
                    this.$message.warning('您已经选择过该权益了')
                    return;
                }
                if(card.left_num<=0){
                    this.$message.warning('该权益已经使用完')
                    return;
                }
                this.form.memberVipCard.push({
                	type: 'usevipcard',
                    id: card.act_item_id,
                	act_item_id: card.act_item_id,
                	name: card.name,
                    rest: card.left_num,
                	number: 1,
                	price: 0,
                	totalPrice: 0,
                	stock: 1,
                	addTime: new Date().getTime(),
                	isService: false
                })
            },
            // 点击选择会员卡
            vipCardClick(card) {
            	// 查询该卡是否已经存在在列表中
                if(this.form.vipCard.find(item=>item.id == card.id)){
                    this.$message.warning('卡项已存在')
                    return;
                }
            	this.form.vipCard.push({
            		type: 'vipcard',
            		disabledNumber: true,
            		id: card.id,
            		name: card.name,
            		number: 1,
            		price: card.price,
            		totalPrice: card.price,
            		addTime: new Date().getTime()
            	})
            	this.sumActualFee()
            },
			// 点击选择充值卡
			rechargeCardClick(card) {
				this.curRechargeCard = card;
				this.curRechargeCardForForm = {
					type: 'rechargecard',
					disabledNumber: true,
					id: card.id,
					name: card.card_name,
					number: 1,
					price: card.recharge_money,
					totalPrice: card.recharge_money,
					addTime: new Date().getTime()
				};
				this.form.rechargeCard = card.id;
				this.sumActualFee()
			},
			setSpecs(item) {
				this.curItem = item;
				// console.log(this.defaultActive)
				let type; // 套餐类型
				// 如果defaultActive 是 6-开头，说明是品牌套餐 如果是7-开头 说明是自营套餐
				if (this.defaultActive && (this.defaultActive.startsWith('6-') || this.defaultActive.startsWith('7-'))) {
					// 1 品牌套餐 2 自营套餐
					type = this.defaultActive.startsWith('6-') ? 1 : 2;
					// 将specs中的id转成数组，调用后台接口
					// 获取套餐的详情
					this.specLoading = true;
					this.$api.group.getSpecDetails({
						ids: item.specs.map(s => s.id),
						type
					}).then(res => {
						// console.log(res.data)
						item.specs.forEach(s => {
							// 找到对应的规格详情
							let detail = res.data.find(detail => s.id == detail.id);
							if(type == 2){
								// 自营套餐返回的数据格式不一样，要处理一下
								let tempGoods = [...detail.goods.platform,...detail.goods.self];
								detail.goods = tempGoods;
								let tempServe = [...detail.serve.platform, ...detail.serve.self];
								detail.serve = tempServe;
							}
							// 将包含的商品和服务，放入detail中
							let goods = detail.goods.map(g => {
								return {
									...g,
									fullname: g.name + '-' + g.sku,
									type: '商品',
								}
							})
							let serves = detail.serve.map(g => {
								return {
									...g,
									fullname: g.name,
									type: '服务',
								}
							})
							s.detail = [...goods, ...serves];
							// console.log(s.detail)
						})
						this.specs = item.specs;
						this.specLoading = false;
					}).catch(err => {
						console.log(err)
						this.specLoading = false;
					})
				} else {
					this.specs = item.specs;
				}


			},
			// 点击分类，获取对应的商品/项目
			getItemList(index) {
            	this.number = index
				// 清空规格
				this.curItem = null;
				this.specs = [];
				this.activeName = 'all';
				this.defaultActive = index;
				// 获取当前分类
				let da = index.split('-');
				if (da[0] == '1') {
					// 如果点击了全部，那么id为undefined
					let classify = [];
					if (this.brandClassify[da[1]] && this.brandClassify[da[1]].id) {
						// 设置二级分类
						this.tabMenu = this.brandClassify[da[1]].children || [];
						// 查询品牌项目接口，需要的分类为子分类id数组，如果没有子分类，则传自己的id数组
						classify = this.brandClassify[da[1]].children.map(item => {
							return item.id
						})
						if (!classify.length) {
							classify = [this.brandClassify[da[1]].id]
						}
					}
					// 查询品牌项目
					this.$api.project.getBrandProjects({
						name:this.name,
						page: 1,
						page_size: 9999,
						classify: classify
					}).then(res => {
						let result = res.data.data.map(item => {
							return {
								cover: item.cover,
								name: item.serve_name,
								price: item.price,
								id: item.id,
								classifyName: item.classify_name,
								specs: [{
									myId: `${da[0]}-${item.id}`,
									name: '默认',
									price: item.price,
									count: 0,
									stock: item.stock,
									code: item.code,
								}]
							}
						});
						this.items = result;
					})
				} else if (da[0] == '2') {
					// 自营项目没有二级分类
					this.tabMenu = [];
					// 查询自营项目
					this.$api.project.getProjects({
						name:this.name,
						page: 1,
						page_size: 9999,
						classify: this.selfClassify[da[1]].id
					}).then(res => {
						let result = res.data.data.map(item => {
							return {
								cover: item.cover,
								name: item.server_name,
								price: item.current_price,
								id: item.id,
								classifyName: item.classify_name,
								specs: [{
									myId: `${da[0]}-${item.id}`,
									name: '默认',
									price: item.current_price,
									count: 0,
									stock: item.stock,
									code: item.code,
								}]
							}
						});
						this.items = result;
					})
				} else if (da[0] == '3') {
					// 如果点击了全部，那么id为undefined
					let classify = [];
					if (this.brandGoodsClassify[da[1]] && this.brandGoodsClassify[da[1]].id) {
						// 设置二级分类
						this.tabMenu = this.brandGoodsClassify[da[1]].children || [];
						// 查询品牌商品接口，需要的分类为子分类id数组，如果没有子分类，则传自己的id数组
						classify = this.brandGoodsClassify[da[1]].children.map(item => {
							return item.id
						})
						if (!classify.length) {
							classify = [this.brandGoodsClassify[da[1]].id]
						}
					}
					// 查询品牌商品
					this.$api.goods.getBrandGoods({
						name:this.name,
						page: 1,
						page_size: 9999,
						classify: classify
					}).then(res => {
						let result = res.data.data.map(item => {
							let specs = item.specs.map(spec => {
								return {
									id: spec.id, // 规格id
									myId: `${da[0]}-${item.id}-${spec.id}`, // 一级分类-商品id-规格id
									name: spec.sku_values,
									price: spec.current_price,
									stock: spec.specs_stock,
									code: spec.specs_sn,
									count: 0
								}
							})
							return {
								cover: item.cover,
								name: item.name,
								price: undefined,
								id: item.id,
								classifyName: item.classify_name,
								specs: specs
							}
						});
						this.items = result;
					})
				} else if (da[0] == '4') {
					// 自营项目没有二级分类
					this.tabMenu = [];
					// 查询自营商品
					this.$api.goods.getGoods({
						name:this.name,
						page: 1,
						page_size: 9999,
						classify: this.selfGoodsClassify[da[1]].id
					}).then(res => {
						let result = res.data.data.map(item => {
							return {
								name: item.name,
								price: item.current_price,
								id: item.id,
								classifyName: item.classify_name,
								cover: item.cover,
								specs: [{
									myId: `${da[0]}-${item.id}`,
									name: '默认',
									price: item.current_price,
									count: 0,
									stock: item.stock,
									code: item.code,
								}]
							}
						});
						this.items = result;
					})
				} else if (index == '5-3') {
					// 获取所有会员卡
					this.$api.card.getVipCards({
						name:this.name
					}).then(res => {
						this.vipCards = res.data.filter(_=>_.status);
					});
				} else if (index == '5-2') {
					// 获取所有充值卡
					this.$api.card.getRecharges({
						name:this.name,
						page: 1,
						page_size: 999
					}).then(res => {
						this.rechargeCards = res.data.data.filter(_=>_.status);
					});
				} else if (index == '5-1') {
					// 获取所有服务卡
					this.$api.card.getServes({
						name:this.name,
						page: 1,
						page_size: 999
					}).then(res => {
						this.serveCards = res.data.data.map(item => {
							return {
								cover: item.card_img,
								name: item.card_name,
								price: item.price,
								id: item.id
							}
						});
					});
				} else if (da[0] == 6) {
					// 品牌套餐不设二级分类，一级分类同项目的一级分类
					this.tabMenu = [];
					// 查询品牌套餐
					this.$api.group.getGroups({
						name:this.name,
						page: 1,
						page_size: 9999,
						classify: this.brandGroupClassify[da[1]].id,
						status: 1,
						type: 2 // 品牌套餐
					}).then(res => {
						let result = res.data.data.map(item => {
							let specs = item.specs.map(spec => {
								return {
									id: spec.id, // 规格id
									myId: `${da[0]}-${item.id}-${spec.id}`, // 一级分类-商品id-规格id
									name: spec.name,
									price: spec.price,
									stock: 999,
									// code: spec.specs_sn,
									count: 0
								}
							})
							return {
								cover: item.cover,
								name: item.name,
								price: undefined,
								id: item.id,
								specs: specs
							}
						});
						this.items = result;
					})
				} else if (da[0] == 7) {
					// 自营套餐不设二级分类，一级分类同项目的一级分类
					this.tabMenu = [];
					// 查询自营套餐
					this.$api.group.getGroups({
						name:this.name,
						page: 1,
						page_size: 9999,
						classify: this.brandGroupClassify[da[1]].id,
						status: 1,
						type: 1 // 自营套餐
					}).then(res => {
						let result = res.data.data.map(item => {
							let specs = item.specs.map(spec => {
								return {
									id: spec.id, // 规格id
									myId: `${da[0]}-${item.id}-${spec.id}`, // 一级分类-商品id-规格id
									name: spec.name,
									price: spec.price,
									stock: 999,
									// code: spec.specs_sn,
									count: 0
								}
							})
							return {
								cover: item.cover,
								name: item.name,
								price: undefined,
								id: item.id,
								specs: specs
							}
						});
						this.items = result;
					})
				}

			},
			tabMenuClick(tab) {
				console.log(tab)
			},
			// 销售人员变更
			salemenChange(val) {
				// 遍历当前选择的所有服务，如果未选择，则默认当前选择的销售人员（前提是销售人员拥有技师角色）
				let selTechincian = this.technicians.find(te => {
					return te.admin_id == val;
				})
				if (selTechincian) {
					this.detailList.forEach(item => {
						if (item.isService && !item.technician) {
							item.technician = selTechincian.id
						}
					})
				}
			},
			// 改价
			editItem(item) {
				// console.log(item)
				this.changeItem.item = item
				this.changeItem.dialogVisible = true
			},
			clearMember() {
				this.member = undefined;
				this.memberName = '';
			},
			queryMember(queryString, callback) {
				this.$api.member.getMembers({
					page: 1,
					page_size: 10,
					phone: queryString
				}).then(res => {
					callback(res.data.data);
				})
			},
			// queryMember(queryString, callback) {
			// 	var members = this.members;
			// 	var results = queryString ? members.filter(this.createFilter(queryString)) : members;
			// 	// 调用 callback 返回建议列表的数据
			// 	callback(results);
			// },
			// createFilter(queryString) {
			// 	return (member) => {
			// 		member.real_name = member.real_name ? member.real_name : '';
			// 		member.phone = member.phone ? member.phone : '';
			// 		return (member.real_name.toLowerCase().indexOf(queryString.toLowerCase()) >= 0 ||
			// 			member.phone.indexOf(queryString) >= 0);
			// 	};
			// },
			memberSelect(item) {
				this.member = item;
				this.form.pay_method = 3;
                // 查询会员的会员卡
                this.$api.member.getVipCards({
                	member_id: this.member.id,
                }).then(res => {
                	console.log(res)
                    this.memberVipCards = res.data;
                })
				// 查询会员的会员卡
				this.$api.member.getMemberCards({
					id: this.member.id,
					page: 1,
					page_size: 999
				}).then(res => {
					res.data.data.forEach(card => {
						// 循环卡中的项目，重构成页面所需要的格式
						card.items = card.card_item.map(item => {
							return {
								card_id: card.id,
								serve_id: item.serve_id,
								count: item.count,
								cover: item.cover,
								name: '[卡]' + item.serve_name,
								price: item.price,
								id: item.id,
								is_use: item.is_use, // 已使用次数
								rest: item.count - item.is_use, // 剩余次数
								type: item.type
							}
						})
					});
					this.memberCardCount = res.data.count;
					this.memberCards = res.data.data;
					
				})
				// 查询会员的套餐
				this.$api.member.getMemberGroups({
					member_id: this.member.id,
					page: 1,
					page_size: 999
				}).then(res => {
					res.data.data.forEach(group => {
						// 循环套餐中的项目，重构成页面所需要的格式
						group.items = group.set_meal_item.map(item => {
							let typeArr = ['', '品牌项目', '自营项目', '品牌商品', '自营商品']
							let pre = typeArr[item.type]
							return {
								group_id: group.id,
								project_id: item.project_id,
								count: item.number,
								cover: item.cover,
								name: pre + item.name,
								price: item.price,
								id: item.id,
								is_use: item.is_use, // 已使用次数
								rest: item.number - item.is_use, // 剩余次数
								type: item.type,
								isService: item.type == 1 || item.type == 2 // 是否为服务
							}
						})
					});
					this.memberGroupCount = res.data.count;
					this.memberGroups = res.data.data;
				})
			},
			inputClick(e) {
				if (e.keyCode == 13) {
					// 执行修改数量
					console.log('add')
				}
			},
			// 添加一个划卡到消费明细
			addCardServe(item) {
				this.form.memberServeCard.push({
					type: 'usecard',
					id: item.id,
					card_id: item.card_id,
					serve_id: item.serve_id,
					name: item.name,
					technician: '',
					number: 1,
					price: 0,
					totalPrice: 0,
					stock: item.rest,
					addTime: new Date().getTime(),
					isService: true
				})
			},
			addGroupItem(item) {
				let typeArr = ['', '品牌项目', '自营项目', '品牌商品', '自营商品']
				let type = typeArr[item.type]
				if (!item.isService) {
					// 从消费明细中查找该套餐&规格
					let pro = this.form.memberSetMeal.find(pro => {
						if (pro.id == item.id) {
							return true;
						}
						return false;
					})
					// 如果包含该套餐&规格，则进行数量+1，如果不包含，则push
					if (pro) {
						pro.number++;
					} else {
						// 添加
						this.form.memberSetMeal.push({
							type: type,
							id: item.id,
							group_id: item.group_id,
							project_id: item.project_id,
							name: item.name,
							technician: item.isService ? '' : undefined,
							number: 1,
							price: 0,
							totalPrice: 0,
							stock: item.rest,
							addTime: new Date().getTime(),
							isService: item.isService
						})
					}
				} else {
					this.form.memberSetMeal.push({
						type: type,
						id: item.id,
						group_id: item.group_id,
						project_id: item.project_id,
						name: item.name,
						technician: '',
						number: 1,
						price: 0,
						totalPrice: 0,
						stock: item.rest,
						addTime: new Date().getTime(),
						isService: item.isService
					})
				}
			},
			// 添加一个规格到消费明细
			addItem(item) {
				// console.log(this.curItem, item)
				// 获取当前分类
				let da = this.defaultActive.split('-');
				// 1 品牌项目 2 自营项目 3 品牌商品 4 自营商品
				// brandProduct: [], // {id,specs_id,number}
				// selfProduct: [], // {id,number}
				// brandServe: [], // {id,technician,number}
				// selfServe: [], // {id,technician,number}
				if (da[0] == 1) {
					// 添加到品牌项目
					// 项目不需要查找，直接添加，因为技师会不一样
					this.form.brandServe.push({
						type: 'brand',
						id: this.curItem.id,
						name: this.curItem.name,
						specName: item.name,
						technician: '',
						number: 1,
						price: item.price,
						totalPrice: item.price,
						addTime: new Date().getTime(),
						isService: true
					})
				} else if (da[0] == 2) {
					// 添加到自营项目
					// 项目不需要查找，直接添加，因为技师会不一样
					this.form.selfServe.push({
						type: 'self',
						id: this.curItem.id,
						name: this.curItem.name,
						specName: item.name,
						technician: '',
						number: 1,
						price: item.price,
						totalPrice: item.price,
						addTime: new Date().getTime(),
						isService: true
					})
				} else if (da[0] == 3) {
					this.addBrandGoods(item);
				} else if (da[0] == 4) {
					this.addSelfGoods(item);
				} else if (da[0] == 6) {
					// 从消费明细中查找该套餐&规格
					let pro = this.form.brandSetMeal.find(pro => {
						if (pro.id == this.curItem.id && pro.specs_id == item.id) {
							return true;
						}
						return false;
					})
					// 如果包含该套餐&规格，则进行数量+1，如果不包含，则push
					if (pro) {
						pro.number++;
					} else {
						// 添加到品牌套餐
						this.form.brandSetMeal.push({
							type: 'brandgroup',
							id: this.curItem.id,
							name: this.curItem.name, // + ' ' + item.name,
							specName: item.name,
							specs_id: item.id,
							number: 1,
							price: item.price,
							totalPrice: item.price,
							// stock: item.stock,
							addTime: new Date().getTime()
						})
					}
				} else if (da[0] == 7) {
					// 从消费明细中查找该套餐&规格
					let pro = this.form.selfSetMeal.find(pro => {
						if (pro.id == this.curItem.id && pro.specs_id == item.id) {
							return true;
						}
						return false;
					})
					// 如果包含该套餐&规格，则进行数量+1，如果不包含，则push
					if (pro) {
						pro.number++;
					} else {
						// 添加到品牌套餐
						this.form.selfSetMeal.push({
							type: 'selfgroup',
							id: this.curItem.id,
							name: this.curItem.name, // + ' ' + item.name,
							specName: item.name,
							specs_id: item.id,
							number: 1,
							price: item.price,
							totalPrice: item.price,
							// stock: item.stock,
							addTime: new Date().getTime()
						})
					}
				}
				this.sumItemTotalPrice();
				// 计算实收金额
				this.sumActualFee()
			},
			// 修改商品数量
			changeItemNumber(val) {

				this.form.number=val
				// if (this.changeItem.item) {
				// 	this.changeItem.item.totalPrice = val * Number(this.changeItem.item.price)
				// }
				if (!val) {
					// 删除
					this.changeItemDel()
				}
				// 计算小计金额
				this.sumTotalPrice();
				// 计算实收金额
				this.sumActualFee()
			},
			// 计算已选商品的小计金额
			sumTotalPrice() {
				this.detailList.forEach(item => {
					item.totalPrice = (Number(item.price) * Number(item.number)).toFixed(2)
				})
			},
			// 计算单品总价
			sumItemTotalPrice() {
				this.form.brandProduct.forEach(item => {
					item.totalPrice = (Number(item.number) * Number(item.price)).toFixed(2)
				})
				this.form.selfProduct.forEach(item => {
					item.totalPrice = (Number(item.number) * Number(item.price)).toFixed(2)
				})
				this.form.brandServe.forEach(item => {
					item.totalPrice = (Number(item.number) * Number(item.price)).toFixed(2)
				})
				this.form.selfServe.forEach(item => {
					item.totalPrice = (Number(item.number) * Number(item.price)).toFixed(2)
				})
				this.form.serveCard.forEach(item => {
					item.totalPrice = (Number(item.number) * Number(item.price)).toFixed(2)
				})
				// 品牌套餐
				this.form.brandSetMeal.forEach(item => {
					item.totalPrice = (Number(item.number) * Number(item.price)).toFixed(2)
				})
				// 自营套餐
				this.form.selfSetMeal.forEach(item => {
					item.totalPrice = (Number(item.number) * Number(item.price)).toFixed(2)
				})
			},
			changeItemNuberKeyDown(e) {
				if (e.key == 'Enter') {
					this.changeItem.dialogVisible = false;
				}
			},
			changeItemDel() {
				let i = -1;
				if (!~i) {
					// 在品牌服务中查找
					i = this.form.brandServe.findIndex(item => {
						return item.number == 0
					});
					// 如果找到了 就删除
					if (~i) {
						this.form.brandServe.splice(i, 1);
					}
				}
				if (!~i) {
					// 在自营服务中查找
					i = this.form.selfServe.findIndex(item => {
						return item.number == 0
					});
					// 如果找到了 就删除
					if (~i) {
						this.form.selfServe.splice(i, 1);
					}
				}
				if (!~i) {
					// 在品牌商品中查找
					i = this.form.brandProduct.findIndex(item => {
						return item.number == 0
					});
					// 如果找到了 就删除
					if (~i) {
						this.form.brandProduct.splice(i, 1);
					}
				}
				if (!~i) {
					// 在自营商品中查找
					i = this.form.selfProduct.findIndex(item => {
						return item.number == 0
					});
					// 如果找到了 就删除
					if (~i) {
						this.form.selfProduct.splice(i, 1);
					}
				}
				if (!~i) {
					// 在服务卡中查找
					i = this.form.serveCard.findIndex(item => {
						return item.number == 0
					});
					// 如果找到了 就删除
					if (~i) {
						this.form.serveCard.splice(i, 1);
					}
				}
				if (!~i) {
					// 在品牌套餐中查找
					i = this.form.brandSetMeal.findIndex(item => {
						return item.number == 0
					});
					// 如果找到了 就删除
					if (~i) {
						this.form.brandSetMeal.splice(i, 1);
					}
				}
				if (!~i) {
					// 在自营套餐中查找
					i = this.form.selfSetMeal.findIndex(item => {
						return item.number == 0
					});
					// 如果找到了 就删除
					if (~i) {
						this.form.selfSetMeal.splice(i, 1);
					}
				}
				if (!~i) {
					// 在划卡中查找
					i = this.form.memberServeCard.findIndex(item => {
						return item.number == 0
					});
					// 如果找到了 就删除
					if (~i) {
						this.form.memberServeCard.splice(i, 1);
					}
				}
				if (!~i) {
					// 在套餐中查找
					i = this.form.memberSetMeal.findIndex(item => {
						return item.number == 0
					});
					// 如果找到了 就删除
					if (~i) {
						this.form.memberSetMeal.splice(i, 1);
					}
				}
                
                if (!~i) {
                	// 在会员卡中查找
                	i = this.form.vipCard.findIndex(item => {
                		return item.number == 0
                	});
                	// 如果找到了 就删除
                	if (~i) {
                		this.form.vipCard.splice(i, 1);
                	}
                }
                if (!~i) {
                	// 在会员卡中查找
                	i = this.form.memberVipCard.findIndex(item => {
                		return item.number == 0
                	});
                	// 如果找到了 就删除
                	if (~i) {
                		this.form.memberVipCard.splice(i, 1);
                	}
                }
                
				if (!~i) {
					// 在充值卡中查找
					if (this.curRechargeCardForForm && this.curRechargeCardForForm.number == 0) {
						this.curRechargeCardForForm = null;
						this.curRechargeCard = null;
					}
				}
                
				// 计算实收金额
				this.sumActualFee()
			},
			// 扫码枪事件
			clearScanGun() {
				this.scanGun.cont = ''
			},
			initScanGun() {
				document.onkeydown = (e) => {
					// console.log(e.path[0])
                    console.log(e.key)
                    if(e.key == 'Backspace' || e.key == 'Delete'){
                        this.clearScanGun()
                    }else if (e.key != 'Enter' && e.keyCode >= 48 && e.keyCode <= 122) {
						this.scanGun.cont = this.scanGun.cont + e.key;
					} else if (e.key == 'Enter') {
						// 执行支付
						if (this.scan.dialogVisible) {
							console.log('执行支付')
							this.form.auth_code = this.scanGun.cont;
							if (this.form.auth_code) {
								this.createOrder();
							}
							this.scanGun.cont = '';
							return;
						}
						// 执行补款
						if (this.rechargeForm.dialogVisible) {
                            console.log('执行补款')
							this.rechargeForm.auth_code = this.scanGun.cont;
							if (this.rechargeForm.auth_code) {
								this.rechargeSubmit()
							}
							this.scanGun.cont = '';
							return;
						}
						// 执行查询商品
                        console.log('执行查询商品')
						this.$api.goods.getGoodsByCode({
							code: this.scanGun.cont
						}).then(res => {
							// console.log(res)
							if (res.code == 200) {
								// 1、品牌商品 2、自营商品
								if (res.data.type == 1) {
									// 构建前端数据
									let sku = {
										id: res.data.specs_id,
										price: res.data.current_price,
										stock: res.data.stock,
										code: res.data.code,
										name: res.data.sku_values,
										myId: undefined,
									}
									res.data.price = res.data.current_price;
									res.data.specs = [sku];
									this.curItem = res.data;
									this.specs = res.data.specs;
									// 设置分类，在点击规格添加的时候会做判断
									this.defaultActive = '3-0';
									// 添加到品牌商品
									this.addBrandGoods(sku);
								} else if (res.data.type == 2) {
									// 构建前端数据
									res.data.price = res.data.current_price;
									res.data.specs = [{
										myId: undefined,
										name: '默认',
										price: res.data.current_price,
										count: 0,
										stock: res.data.stock,
										code: res.data.code,
									}]
									this.curItem = res.data;
									this.specs = res.data.specs;
									// 设置分类，在点击规格添加的时候会做判断
									this.defaultActive = '4-0';
									// 添加到自营商品
									this.addSelfGoods(res.data);
								}
								// 计算单品总价
								this.sumItemTotalPrice();
								// 计算实收金额
								this.sumActualFee()

							} else {
								this.$message.error(res.msg)
							}
						})
						this.scanGun.cont = "";
					}
					// console.log(this.scanGun.cont)
				}
				// 页面焦点
				window.onfocus = () => {
					this.scanGun.focus = true;
					// console.log('window focus')
				}
				window.onblur = () => {
					this.scanGun.focus = false;
					// this.$message.info('页面已失去焦点')
				}
			},
			destroyScanGun() {
				// console.log('清除扫码枪相关事件')
				document.onkeydown = undefined;
				window.onfocus = undefined;
				window.onblur = undefined;
			},
			discountChange(e) {
				if (e.key == 'Enter') {
					this.discount.dialogVisible = false;
				}
			},
			// 修改了优惠中的实收金额
			changeActual() {
				// 计算优惠金额
				this.discount.discount = (Number(this.totalFee) - Number(this.form.actual_fee)).toFixed(2)
			},
			// 计算实收金额
			sumActualFee() {
				this.form.actual_fee = (Number(this.totalFee) - Number(this.discount.discount)).toFixed(2)
			},
			initPage() {
				// 清空提交的表单
				this.form = {
					auth_code: '',
					brandProduct: [], // {id,specs_id,number}
					selfProduct: [], // {id,number}
					brandServe: [], // {id,technician,number}
					selfServe: [], // {id,technician,number}
					brandSetMeal: [],
					memberSetMeal: [],
					selfSetMeal: [],
					member_id: '', // 会员id
					sale: '', // 销售人员
					actual_fee: 0, // 实付金额
					discount_amount: 0, // 优惠金额
					rechargeCard: '', // 充值卡id
					serveCard: [], // 办卡，服务卡
					vipCard: [], // 超级会员卡
					memberServeCard: [], // 划卡
					memberVipCard:[], // 划会员卡
					pay_method: 1 ,// 支付类型  1 微信  2 支付宝  3 会员余额 4 现金
				};
				// 清空优惠金额
				this.discount.discount = 0;
				// 清空选择的充值卡
				this.curItem = null;
				this.curRechargeCard = null;
				this.curRechargeCardForForm = null;
				// 清空选择的服务卡
				this.curServeCard = null;
				// 清空会员
				this.member = undefined;
				// 清空选择规格
				this.specs = []
			},
			submit() {
                // console.log('enter')
				console.log(this.detailList)
				// 校验，是否选择商品
				if (!this.detailList.length) {
					this.$message.error('请选择项目或商品');
					return;
				}
				// 校验套餐商品、服务，是否超出剩余数量
				let tempMemberSetMeal = JSON.parse(JSON.stringify(this.memberGroups)).map(item => item.items)
				tempMemberSetMeal = [].concat.apply([], tempMemberSetMeal); // 转一维数组
				for (let i = 0; i < this.form.memberSetMeal.length; i++) {
					let item = this.form.memberSetMeal[i]
					// 拿每一条数据，去原数据中减去数量，如果小于0，则提示超出
					let org = tempMemberSetMeal.find(meal => item.id == meal.id)
					org.rest = org.rest - item.number;
					if (org.rest < 0) {
						this.$message.error(`【${item.name}】使用数量超出剩余数量`)
						return;
					}
				}
				// 校验，是否选择技师
				let brandServe = this.form.brandServe.find(item => !item.technician)
				let selfServe = this.form.selfServe.find(item => !item.technician)
				let memberGroupServe = this.form.memberSetMeal.find(item => !item.technician && item.isService)
				let memberServeCard = this.form.memberServeCard.find(item => !item.technician)
				if (brandServe || selfServe || memberServeCard || memberGroupServe) {
					this.$message.error('请选择服务人员');
					return;
				}
                debugger
				// 校验，如果明细中包含服务卡或充值卡或套餐，那么校验会员必选
                var test = this.form.vipCard.length || this.form.rechargeCard || this.form.serveCard.length || this.form.brandSetMeal.length || this.form.selfSetMeal.length;
				if (test && !this.form.member_id) {
					this.$message.error('请选择会员');
					return;
				}

				// 判断收款方式
				if (!Number(this.form.actual_fee)) {
					// 如果支付金额为0，则默认现金支付，不走扫码流程
					this.form.pay_method = 4;
					this.createOrder();
				} else if (this.form.pay_method == 1) {
					// this.$message.success('请扫描顾客的微信付款码');
					this.scan.dialogVisible = true;
					this.$nextTick(() => {
						document.getElementById('cancel').focus()
					})
				} else if (this.form.pay_method == 2) {
					// this.$message.success('请扫描顾客的支付宝付款码');
					this.scan.dialogVisible = true;
					this.$nextTick(() => {
						document.getElementById('cancel').focus()
					})
				} else if (this.form.pay_method == 3) {
					// 校验是否有充值卡，如果有则不能使用余额支付
					if (this.form.rechargeCard) {
						this.$message.error('订单中包含充值卡，不能使用余额支付')
						return;
					}
					// 校验余额是否充足
					if (!this.member) {
						this.$message.error('请选择会员')
						return;
					}
					if (Number(this.member.balance) < Number(this.form.actual_fee)) {
						let cha = (Number(this.form.actual_fee) - Number(this.member.balance)).toFixed(2);
						this.$confirm(`当前会员余额不足，需补款${cha}元，是否立即补款？`, '提示', {
							type: 'warning',
							confirmButtonText: '是',
							cancelButtonText: '取消支付'
						}).then(() => {
							// 打开补款
							this.rechargeForm.dialogVisible = true;
							this.rechargeForm.actual_fee = cha;
                            this.$nextTick(()=>{
                                this.$refs.cancelBtn.$el.focus();
                            })
						}).catch(() => {
							this.$message({
								type: 'info',
								message: '已取消操作'
							});
						});
						return;
					}
					this.createOrder();
				} else if (this.form.pay_method == 4) {
					this.$confirm('请确保您已收取顾客现金', '提示', {
						type: 'warning',
						confirmButtonText: '已收款',
						cancelButtonText: '取消订单'
					}).then(() => {
						this.createOrder();
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消操作'
						});
					});
				}
			},
			createOrder() {
				this.scan.dialogVisible = false;
				const loading = this.$loading()
				this.form.discount_amount = this.discount.discount;
				return this.$api.order.create(this.form).then(res => {
					loading.close()
					if (res.code == 200) {
						this.$message({
							type: 'success',
							message: '操作成功!'
						});
                        // 等待付款完成
                        this.waitUserPass.orderId = res.data.order_id;
                        this.waitUser(()=>{
                            this.initPageData()
                            this.initPage()
                        })
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				});
			},
			// 添加品牌商品
			addBrandGoods(item) {
				// 从消费明细中查找该商品&规格
				let pro = this.form.brandProduct.find(pro => {
					if (pro.id == this.curItem.id && pro.specs_id == item.id) {
						return true;
					}
					return false;
				})

				// 如果包含该商品&规格，则进行数量+1，如果不包含，则push
				if (pro) {
					if (item.stock > pro.number + 1) {
						pro.number++;
					} else {
						this.$message.warning('库存不足')
					}
				} else {
					if (item.stock > 0) {
						// 添加到品牌商品
						this.form.brandProduct.push({
							type: 'brandgoods',
							id: this.curItem.id,
							name: this.curItem.name, // + ' ' + item.name,
							specName: item.name,
							specs_id: item.id,
							number: 1,
							price: item.price,
							totalPrice: item.price,
							stock: item.stock,
							addTime: new Date().getTime()
						})
					} else {
						this.$message.warning('库存不足')
					}
				}
			},
			// 添加自营商品
			addSelfGoods(item) {
				// 从消费明细中查找该商品&规格
				let pro = this.form.selfProduct.find(pro => {
					if (pro.id == this.curItem.id) {
						return true;
					}
					return false;
				})
				// 如果包含该商品&规格，则进行数量+1，如果不包含，则push
				if (pro) {
					if (item.stock > pro.number + 1) {
						pro.number++;
					} else {
						this.$message.warning('库存不足')
					}
				} else {
					if (item.stock > 0) {
						// 添加到自营商品
						this.form.selfProduct.push({
							type: 'selfgoods',
							id: this.curItem.id,
							name: this.curItem.name,
							specName: item.name,
							number: 1,
							price: item.price,
							totalPrice: item.price,
							stock: item.stock,
							addTime: new Date().getTime()
						})
					} else {
						this.$message.warning('库存不足')
					}
				}
			}

		},
	};
</script>

<style scoped lang="scss">
    .system-status{
        position: relative;
        box-sizing: border-box;
        padding-bottom: 0;
        .input-text{
            font-size: 12px;
            color: #ddd;
            height: 15px;
            line-height: 15px;
        }
    }
	.order {
		display: flex;
		justify-content: space-between;
		height: 100%;

		.border-bottom {
			border-bottom: 1px solid #e0e0e0;
		}

		.border-left {
			border-left: 1px solid #eee;
		}

		.right {
			width: 400px;
			border-left: 1px solid #e6e6e6;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			.staffs {
				padding-top: 10px;
				display: flex;
				flex-wrap: wrap;

				.staff {
					width: 80px;
					height: 30px;
					padding: 0 10px;
					display: flex;
					align-items: center;
				}
			}

			.settlement-box {
				height: 40px;
				box-shadow: 0px 0px 3px rgba($color: #cdd7de, $alpha: 1);
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 10px;

				span {
					color: var(--red);
				}

				.discount {
					color: var(--grey);
					font-size: 12px;

					span {
						color: var(--grey);
					}
				}
			}

			.salemain-box {
				font-size: 13px;
				padding: 2px 8px;
				border-top: 1px solid #e0e0e0;
			}

			.order-detail2 {
				width: 394px;
				margin: 0 auto 5px;
				border: 1px solid #e0e0e0;
				font-size: 13px;
				color: #4c4c4c;
				line-height: 16px;
				box-sizing: border-box;

				.info {
					padding: 8px;

					.total-price {
						color: #f58337;
						font-size: 16px;
					}
				}

				.staff {
					padding: 2px 8px;
					border-top: 1px solid #e0e0e0;
					// .el-select>>>.el-input__inner{
					// 	text-align: right;
					// 	border: 1px solid #fff;
					// }
				}

			}

			.order-detail-box {
				flex: 1;
				display: flex;
				flex-direction: column;
				justify-content: space-between;



				.order-detail-scroll {
					padding-top: 5px;
					flex: 1 0 auto;
					height: 0;
					overflow-y: auto;
					overflow-x: hidden;
				}

				.order-detail-tit {
					font-weight: bold;
					padding-top: 5px;
					font-size: 14px;
					display: flex;
					justify-content: space-between;
				}

				.order-detail {
					.info {
						padding-top: 5px;
						font-size: 14px;
						display: flex;
						justify-content: space-between;

						&:hover {
							background-color: #ecf5ff;
							cursor: pointer;
						}
					}

					.staff {
						padding-left: 20px;
						display: flex;
						color: #999;
						justify-content: center;
						align-items: center;
						font-size: 14px;
						// background-color: #eee;
					}


				}

				.name {
					width: 150px;
					padding-left: 5px;
				}

				.count {
					width: 50px;
					text-align: center;
				}

				.price,
				.amount {
					width: 100px;
				}


			}

			.member-input {
				width: 100%;
			}

			.title {
				height: 30px;
				color: #555;
				font-size: 14px;
				line-height: 30px;
				text-align: center;
				box-shadow: 0px 0px 3px rgba($color: #cdd7de, $alpha: 1);
			}

			.member {
				height: 60px;
				padding: 10px;
				display: flex;
				align-items: center;

				i {
					font-size: 26px;
					color: #e6e6e6;
					cursor: pointer;

					&:hover {
						color: var(--orange);
					}
				}

				.user-info {
					flex: 1;
					margin-left: 10px;
					display: flex;
					flex-direction: column;
					justify-content: center;

					.name {
						color: #333;
						font-size: 26px;
						height: 30px;
						line-height: 30px;
					}

					.balance {
						margin-top: 5px;
						font-size: 20px;
						height: 20px;
						line-height: 20px;
						color: var(--orange);
					}
				}

				.el-image,
				.el-avatar {
					width: 60px;
					height: 60px;
					border-radius: 5px;
					display: flex;
					justify-content: center;
					align-items: center;

					& /deep/ .el-icon-user-solid {
						font-size: 30px;
					}
				}


			}
		}

		.content {
			flex: 1;
			height: 100%;
			overflow-y: auto;

			.items {
				display: flex;
				flex-wrap: wrap;
			}

			.item {
				width: 220px;
				margin: 0 20px 20px 0;
				height: 88px;
				border: 2px solid #e0e0e0;
				padding: 8px;
				box-sizing: border-box;
				display: flex;
				cursor: pointer;

				&.cur {
					border: 2px solid var(--green);
				}

				&:hover {
					border: 2px solid var(--green);
				}

				.cont {
					flex: 1;
					margin-left: 10px;
					position: relative;

					.tit {
						height: 52px;
						line-height: 20px;
						font-size: 16px;
						color: #4c4c4c;
					}

					.price-box {
						display: flex;
						justify-content: space-between;
						align-items: center;
						position: relative;


						.price {
							font-size: 16px;
							line-height: 20px;
							color: #f58337;
						}
					}

				}

				.el-image {
					width: 68px;
					height: 68px;
					border-radius: 5px;
				}
			}
		}

		.menu {
			width: 188px;
			height: 100%;
			border-right: solid 1px #e6e6e6;
			overflow-y: scroll;

			.el-collapse-item  /deep/ .el-collapse-item__header {
				padding-left: 5px;
				height: 33px;
				background-color: var(--grey);
				color: #fff;
			}

			.el-collapse-item  /deep/ .el-collapse-item__content {
				display: flex;
				padding-left: 5px;
				flex-wrap: wrap;
				padding-bottom: 5px;
				padding-top: 10px;

				.my-menu-item {
					width: 80px;
					height: 40px;
					margin-right: 5px;
					margin-bottom: 5px;
					border: 1px solid #e0e0e0;
					line-height: 40px;
					text-align: center;
					font-size: 13px;
					cursor: pointer;

					&.cur {
						color: #67c23a;
						border: 1px solid #67c23a;
						background-color: #f0ffed;
					}

					&:hover {
						background-color: #f0ffed;
					}
				}
			}


			.el-menu {
				border-right: none;

				.el-menu-item,
				.el-submenu__title {
					height: 30px;
					line-height: 30px;
				}

				.el-menu-item.is-active {
					background-color: #ecf5ff;
				}
			}
		}
	}

	.specs {
		font-size: 13px;
		color: #4c4c4c;

		.goods_name {
			border-bottom: 1px solid #e0e0e0;
			margin-bottom: 20px;
		}

		.spec {
			display: flex;
			justify-content: space-between;
			height: 40px;
			line-height: 40px;
			padding: 0 10px;

			.plus {
				width: 18px;
				height: 18px;
				cursor: pointer;
			}
		}

		.count {
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: 24px;
			color: var(--blue);
			// opacity: 0.2;

			i {
				font-size: 30px;
				cursor: pointer;
			}

			.el-input {
				width: 43px;
				margin: 0 5px;
				font-size: 20px;
			}

			.el-input /deep/ input {
				padding: 0;
				text-align: center;
			}

			&.cur {
				color: var(--blue);
				opacity: 1;
			}

		}
	}

	.payment {
		display: flex;
		align-items: center;
		padding: 0 10px;
		margin-right: 0px;

		&.wxpay.cur {
			background-color: var(--greenLight);

			& /deep/ .el-radio__input.is-checked .el-radio__inner {
				background-color: var(--green);
				border-color: var(--green);
			}
		}

		&.alipay.cur {
			background-color: var(--blueLight);

			& /deep/ .el-radio__input.is-checked .el-radio__inner {
				background-color: var(--blue);
				border-color: var(--blue);
			}
		}

		&.cash.cur {
			background-color: var(--orangeLight);

			& /deep/ .el-radio__input.is-checked .el-radio__inner {
				background-color: var(--orange);
				border-color: var(--orange);
			}
		}

		&.balancepay.cur {
			background-color: var(--redLight);

			& /deep/ .el-radio__input.is-checked .el-radio__inner {
				background-color: var(--red);
				border-color: var(--red);
			}
		}

		& /deep/ .el-radio__label {
			height: 40px;
			display: flex;
			align-items: center;
		}

	}

	.el-radio-group.payments {
		display: flex;
		justify-content: center;
	}

	.border-top {
		border-top: 1px solid #ddd;
	}

	.card {
		.card-box {
			margin-top: 10px;
			border: 1px solid #999;
			// padding: 20px;

			.card-name {
				background-color: #eee;
				padding: 10px;
			}
		}
	}

	.el-tabs /deep/ .el-tabs__item.is-active {
		color: #67c23a;
	}
</style>
